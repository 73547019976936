import './styles.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import CameraPhoto, {
  FACING_MODES,
  IMAGE_TYPES,
} from 'jslib-html5-camera-photo';

import { MAX_WIDTH, MIN_WIDTH } from 'constant';
import useGlobalState from 'actions';

import DialogCamera from 'components/Dialog/DialogCamera';
import { useUserContext } from 'providers/UserProvider';

import circle from 'assets/img/circle.png';
import leftArrow from 'assets/img/icon/ic-back.svg';
import ktpPhotoFrame from 'assets/img/ktp_photo_frame.png';

import { Button, ButtonOutline, Div } from 'assets/css/styled';

const BackCameraUniversal = (props) => {
  const [state, actions] = useGlobalState();
  const { handleNotification } = useUserContext();

  const [isTaken, setIsTaken] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [cameraPhoto, setCameraPhoto] = useState(() => {});
  const [cameraViewControl, setCameraViewControl] = useState(null);
  const [cameraOutputControl, setCameraOutputControl] = useState(null);
  const [dialogGuidance, setDialogGuidance] = useState(props?.dialogData);

  let config = {
    sizeFactor: 1,
    imageType: IMAGE_TYPES.JPG,
    imageCompression: 1,
    isImageMirror: false,
  };

  useEffect(() => {
    if (props?.type === 'ktp' || props?.type === 'ktp_self') {
      setDialogGuidance((prev) => ({
        ...prev,
        ...props.dialogData,
      }));
    }
  }, [props?.dialogData]);

  useEffect(() => {
    if (cameraPhoto?.stream) {
      cameraPhoto.stopCamera();
    }

    if (!state.isPhotoDialogShown || !cameraViewControl || isRendered) {
      return;
    }

    (async () => {
      actions.openLoadingOverlay();
      const tempCameraPhoto = new CameraPhoto(cameraViewControl);
      setCameraPhoto(tempCameraPhoto);

      try {
        await tempCameraPhoto.startCamera(FACING_MODES.ENVIRONMENT, {
          width: 640,
          height: 480,
        });

        setIsRendered(true);
      } catch (error) {
        const errorName = error?.name;
        handleNotification({
          isOpen: true,
          message:
            errorName === 'NotAllowedError'
              ? 'Mohon aktifkan permintaan akses kamera'
              : 'Ada gangguan kamera, silakan coba lagi',
        });
      } finally {
        actions.closeLoadingOverlay();
      }
    })();
  }, [cameraViewControl]);

  useEffect(() => {
    return () => actions.setState('isPhotoDialogShown', false);
  }, []);

  return (
    <Dialog
      open={state.isPhotoDialogShown}
      onClose={() => actions.setState('isPhotoDialogShown', false)}
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: '#000',
        },
      }}
    >
      <DialogContent style={{ padding: '0px' }}>
        <div
          className='camera'
          style={{ backgroundColor: '#222222', height: '100vh' }}
        >
          <div
            style={{ cursor: 'pointer', padding: '16px', color: '#ffffff' }}
            onClick={() => {
              try {
                setIsRendered(false);
                setIsTaken(false);
                if (cameraPhoto.stream) {
                  cameraPhoto.stopCamera();
                }
              } catch (error) {
                actions.setState('isPhotoDialogShown', false);
                if (props.onCancel) props.onCancel(true);
              } finally {
                if (props.onCancel) props.onCancel(true);
                actions.setState('isPhotoDialogShown', false);
              }
            }}
          >
            <img src={leftArrow} style={{ marginRight: '20px' }} />
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
              Foto {props.name}
            </span>
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'fixed',
            }}
          >
            <div style={{ height: '225px', width: '350px' }}>
              <video
                ref={(videoCamera) => setCameraViewControl(videoCamera)}
                className={`cameraViewBack ${
                  props.type !== 'ktp' && 'border border-secondary'
                }`}
                autoPlay
                playsInline
              />
              <img
                src='//:0'
                alt=''
                ref={(cameraOutput) => setCameraOutputControl(cameraOutput)}
                className='cameraOutputBack'
                style={{
                  height: '225px',
                  width: '350px',
                  position: 'fixed',
                  left: 'calc(50% - 175px)',
                  marginTop: '50px',
                  display: isTaken ? 'block' : 'none',
                }}
              />
              {props.type === 'ktp' && !isTaken && (
                <img
                  style={{
                    height: '225px',
                    width: '350px',
                    position: 'fixed',
                    left: 'calc(50% - 175px)',
                    marginTop: '50px',
                  }}
                  src={ktpPhotoFrame}
                />
              )}
            </div>
            <div className='cameraTriggerContainerBack'>
              {isTaken ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    align: 'center',
                    left: '0%',
                    bottom: '5%',
                    transform: 'translate(0%, -50%)',
                  }}
                >
                  <ButtonOutline
                    borderless
                    style={{ width: '100px' }}
                    className='text-white mx-3'
                    onClick={() => {
                      setIsTaken(false);
                      const tempCameraPhoto = new CameraPhoto(
                        cameraViewControl,
                      );

                      setCameraPhoto(tempCameraPhoto);

                      tempCameraPhoto
                        .startCameraMaxResolution(FACING_MODES.ENVIRONMENT)
                        .then(() => {})
                        .catch(() => {});

                      setCameraOutputControl({
                        ...cameraOutputControl,
                        src: '//:0',
                      });

                      if (props.onImageRetry) {
                        props.onImageRetry();
                      }
                    }}
                  >
                    Foto Ulang
                  </ButtonOutline>
                  <Button
                    types='primary'
                    style={{ width: '100px' }}
                    className='mx-3'
                    onClick={() => {
                      if (props.onImageSubmitted) {
                        var sp = cameraOutputControl.src.split(',');
                        if (sp.length === 2) {
                          if (sp[1] === '') {
                            alert(state.cameraAllowError);
                          } else {
                            try {
                              if (cameraPhoto?.stream) {
                                cameraPhoto.stopCamera();
                                props.onImageSubmitted(cameraOutputControl.src);
                                setIsRendered(false);
                                setIsTaken(false);
                                actions.setState('isPhotoDialogShown', false);
                              }
                            } catch (error) {
                              actions.setState('isPhotoDialogShown', false);
                            }
                          }
                        }
                      }
                    }}
                  >
                    OK
                  </Button>
                </div>
              ) : (
                <img
                  alt='Take Photo'
                  src={circle}
                  style={{
                    cursor: 'pointer',
                    display: 'block',
                    position: 'fixed',
                    left: '50%',
                    bottom: '5%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  onClick={() => {
                    if (cameraPhoto && isRendered) {
                      setIsTaken(true);

                      let imageBase64 = cameraPhoto.getDataUri(config);
                      cameraOutputControl.src = imageBase64;
                      if (props.onImageTaken) {
                        props.onImageTaken(cameraOutputControl.src);
                      }
                    }
                  }}
                />
              )}
            </div>
          </div>
          <Div maxWidth={MAX_WIDTH} minWidth={MIN_WIDTH} position='absolute'>
            {dialogGuidance?.content ? (
              <DialogCamera data={dialogGuidance} />
            ) : null}
          </Div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

BackCameraUniversal.propTypes = {
  dialogData: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.any,
  }),
  onCancel: PropTypes.func,
  type: PropTypes.any,
  name: PropTypes.string,
  onImageTaken: PropTypes.func,
  onImageRetry: PropTypes.func,
  onImageSubmitted: PropTypes.func,
};

export default BackCameraUniversal;
